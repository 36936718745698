.my-table {
  border-collapse: collapse;
  width: 100%;
}

.my-table th,
.my-table td {
  padding: 8px;
  text-align: left;
  border-bottom: 1px solid #ddd;
}

.my-table th {
  background-color: #f2f2f2;
}

.my-table tr:hover {
  background-color: #f5f5f5;
}
